import React, { useEffect, useState, useRef } from "react";
import {isMobile} from 'react-device-detect';

function PlateVisualization({src,rects}) {
  const canvasRef = useRef(null);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    
    console.log(`Windows size ${innerWidth} x ${innerHeight}`)
    return {innerWidth, innerHeight};
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());
  
  function drawCanvas(context,image){
      // Set the image as the background

      let ratio = window.devicePixelRatio || 1

      

      var x,y = 0 
      var scaleFactor = 0
      var width, height

      // Resize canvas to fit image 
      if (!isMobile) {
        context.canvas.width = window.innerWidth*0.75*ratio        
        context.canvas.height = window.innerWidth*0.75*ratio / image.width * image.height
        width = window.innerWidth*0.75
        height = window.innerWidth*0.75 / image.width * image.height
        console.log(`Desktop mode`)
      }
      else {
        context.canvas.width = window.innerWidth*ratio
        context.canvas.height = window.innerWidth*ratio / image.width * image.height
        width = window.innerWidth
        height = window.innerWidth / image.width * image.height
        console.log(`Mobile mode`)
      }
      context.canvas.style.width = width + "px"
      context.canvas.style.height = height + "px"
      context.scale(ratio,ratio)

      if (image.width/image.height < context.canvas.width/context.canvas.height)  {
        // Fill width
        x = (width- image.width * height/image.height)/2
        y = 0
        context.drawImage(image, x,y , image.width * height/image.height  , height);  
        scaleFactor = height/image.height
      }
      else {
        // Fill height
        x = 0
        y = (height - width/image.width * image.height)/2
        context.drawImage(image, x,y , width , width/image.width * image.height);
        scaleFactor = width/image.width
      }
      
      

      // Set the style for your rectangle
      context.strokeStyle = 'red'; // Rectangle border color
      context.lineWidth = 2; // Rectangle border width
      console.log(`Canvas size:${context.canvas.width}x${context.canvas.height}`)
      console.log(`Scale factor:${scaleFactor}, ratio ${ratio}`)
      // Draw the rectangle
      // context.strokeRect(50, 30, 110, 90); // x, y, width, height
      console.log(`x=${x},y=${y}`)
      if (rects != null)
      for (var rect of rects){
        console.log(`Rect:${rect}`)
        console.log(`Draw at: x = ${x + rect[0]*scaleFactor}, y = ${y+rect[1]*scaleFactor}, w = ${(rect[2]-rect[0])*scaleFactor}, h = ${(rect[3]-rect[1])*scaleFactor}  `)
        context.strokeRect(x + rect[0]*scaleFactor,y+rect[1]*scaleFactor,(rect[2]-rect[0])*scaleFactor,(rect[3]-rect[1])*scaleFactor)
      }

  }
  function handleWindowResize() {
    
    setWindowSize(getWindowSize());
    
  }
  useEffect(() => {
    
    window.addEventListener('resize', handleWindowResize);

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const image = new Image();
    
    //context.fillStyle = "blue";
    //context.fillRect(0, 0, canvas.width, canvas.height);
    // Load your image
    console.log(`Loading image ${src}`)
    image.src = src;

    image.onload = () => {
      drawCanvas(context,image,windowSize)    
    
    };
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [src,rects,windowSize]);

  return (
    <canvas ref={canvasRef} width={0} height={0} />
  );
}

export default PlateVisualization;