
import React, { useEffect, useState, useRef } from "react";
import './App.css';
import styled from "styled-components";
import PlateVisualization from "./PlateVisualization";
//const baseUrl = "http://127.0.0.1:3000"
const baseUrl = "https://ai.mdcsoftware.com.vn"
const theme = {
  green: {
    default: "#33cc33",
    hover: "#70db70",
  },
  red: {
    default: "#e60000",
    hover: "#ff6666",
  },
};
const Button = styled.button`
  background-color: ${(props) => theme[props.theme].default};
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  outline: 0;
  border: 0; 
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  transition: ease background-color 250ms;
  &:hover {
    background-color: ${(props) => theme[props.theme].hover};
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

Button.defaultProps = {
  theme: "green",
};

function clickMe() {
  alert("You clicked me!");
}
const PlateDetection = () => {
  
  //const canvasRef = useRef(null);
  console.log(`API URL:${baseUrl}`)
  
  useEffect(() => {
    document.title = 'MDC Plate Detection Demo';
    // const canvas = canvasRef.current;
    // const context = canvas.getContext('2d');
    // const image = new Image();
  }, []);
  const [image, setImage] = useState(null)
  const [detected,setDetected] = useState(null)
  const [rects, setRects] = useState(null)
  const [requestTime,setRequestTime] = useState(0)
  const [processingTime,setProcessingTime] = useState(0)
  



  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setRects(null)
      uploadFile(event.target.files[0])

    }
   }

   function reportCorrect(item,index){
      console.log(`Item ${index}:Plate ${item.plate} is CORRECT`);
      fetch(`${baseUrl}/api/v1/plate/feedback`,{
        method:'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },        
        body:JSON.stringify({
          plate:item.plate,
          correct:true,
          image:item.image
        })
      }).then(response=>response.json())
      .then(json=>{
          
          alert(json.reason)

          
      });
   }
   function reportWrong(item,index){
      console.log(`Item ${index}:Plate ${item.plate} is WRONG`);
      const correct_label = prompt('Enter correct license plate:')
      console.log(`Item ${index}:Plate ${item.plate} is CORRECT`);
      fetch(`${baseUrl}/api/v1/plate/feedback`,{
        method:'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },        
        body:JSON.stringify({
          plate:correct_label,
          correct:false,
          image:item.image,
          predicted:item.plate 
        })
      }).then(response=>response.json())
      .then(json=>{          
          alert(json.reason)

      });
   }
   function uploadFile(file) {

    var formData = new FormData();

  
    formData.append(`file`, file);
    
    let beginTime = Date.now()
    fetch(`${baseUrl}/api/v1/plate/recognize`, {
      // content-type header should not be specified!
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(success => {
        let endTime = Date.now()
        setRequestTime((( endTime - beginTime )/1000 - success.processing_time).toFixed(3))
        setProcessingTime(success.processing_time.toFixed(3))
        // Do something with the successful response
        setDetected(success.plates)
        let detectedRects = []
        for (var plate of success.plates)
          detectedRects.push(plate.x1y1x2y2)
        setRects(detectedRects)
        console.log(success)
      })
      .catch(error => console.log(error)
    );
  }
  function sayHello() {
    alert('Hello, World!');
  }
  function tableResult(items){
    return (
      <div>
        <table className="main-table" style={{width:"100%"}}>
            
            {
                items.map((item,index)=>{
                  return <><tr>
                    <td className="main-td" style={{textAlign:"center"}}>
                      <img src={`data:image/jpg;base64,${item.image}`}/>            
                    </td>
                    <td className="main-td">
                      <table>
                        
                        <tr>
                          <td>Plate</td>
                          <td style={{"font-weight":"bold"}}>
                          {item.plate}         
                          </td>
                        </tr>
                        <tr>
                          <td>OCR Score</td>
                          <td>
                          {item.conf}%
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Type
                          </td>
                        <td>
                      {item.type.toUpperCase()}
                    </td>                    
                        </tr>
                        <tr>
                      <td>
                        Plate Score
                      </td>
                      <td>
                      {item.box_conf.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      
                    </tr>
                    </table>
                    
                    </td>
                    
                  </tr>
                  <tr>
                    
                      <td colSpan={"2"}>
                        <div style={{display:"flex","justify-content": "center"}}>
                          <Button theme="green" style={{marginLeft:"30%",marginRight:"auto"}} onClick={()=>reportCorrect(item,index)}>Correct</Button>                          
                          <Button theme="red" style={{marginLeft:"auto",marginRight:"30%"}} onClick={()=>reportWrong(item,index)}>Wrong</Button></div> 
                      
                      </td>
                  
                  
                  </tr>
                  </>
                  
                })
            }
          
        </table>
      </div>);
  }
  return (
    <div>
      <div id="header" style={{paddingTop:"50px"}}>
        
      </div>
      <div style={{display:"inline"}}>
      <img src="logo-small.png" style={{width:"100px"}}></img>
      <h1>PLATE DETECTION AND RECOGNIZATION</h1>
      <p>Version 0.1-alpha-2024.05.09</p>
      </div>
      
    {/* {
      image ? <img alt="preview image" style={{maxWidth:"75%"}} src={image}/>:""
    } */}
    <PlateVisualization src={image} rects={rects}/>
    <br/>
    <input type="file" onChange={onImageChange} className="filetype" />
    
    
    
    {
      
      detected ?  tableResult(detected) : <p>None detected</p>
    }
    <p>Network transfer time: {requestTime} second(s)</p>
    <p>Processing time: {processingTime} second(s)</p>
 <div id="footer" style={{paddingBottom:"50px"}}>
  
  </div>   
    </div>
    
  );
};

export default PlateDetection;